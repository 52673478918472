<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <b-form-group
              id="input-group-name"
              label="Название"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-audience"
              label="Аудитория"
              label-for="input-audience"
            >
              <b-form-select
                v-model="f.audience"
                :options="audiencesOptions"
                id="input-audience"
              />
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-type"
              label="Тип блока"
              label-for="input-type"
            >
              <b-form-select
                v-model="f.type"
                :options="typeBlocksOptions"
                id="input-type"
              />
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-is-active"
              label="Активен"
              label-for="input-is-active"
            >
              <switch-checkbox v-model="f.is_active" label="Активен" />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
import SwitchCheckbox from '../SwitchCheckbox.vue';
import { mapGetters } from 'vuex';

export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        name: this.$route.query?.name,
        audience: this.$route.query?.audience,
        type: this.$route.query?.type,
        is_active: this.$route.query?.is_active === 'true' || false,
      },
    };
  },

  computed: {
    ...mapGetters(['audiences', 'typeBlocks']),
    audiencesOptions() {
      const results = [''];
      this.audiences
        .map((x) => x.title)
        .forEach((item) => {
          results.push(item);
        });
      return results;
    },

    typeBlocksOptions() {
      const results = [''];
      this.typeBlocks
        .map((x) => x.title)
        .forEach((item) => {
          results.push(item);
        });
      return results;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
